const openBlockModalKey = Symbol('openBlockFunctionalityModal');
const limitExceddedInTrialModalKey = Symbol('limitExceddedInTrialModal');
const openBlockedByAdminModalKey = Symbol('openBlockedByAdminModal');
const legacyPropsKey = Symbol('legacyProps');

export {
  openBlockModalKey,
  limitExceddedInTrialModalKey,
  openBlockedByAdminModalKey,
  legacyPropsKey,
};
